<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            rounded="sm"
        >
          <b-form>
            <b-row>

              <map-editor
                  ref="map-editor"
                  :map-url="this.formData.mapUrl"
                  :markers="this.formData.markers"
              ></map-editor>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  Mentés
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BLink, BOverlay, BAlert,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import tabs from '@/views/pages/_components/_tabs/_map/update.vue'
import mapEditor from "@/views/pages/_components/_map/MapEditor";
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BLink,
    BOverlay,
    BAlert,
    tabs,
    cancelAndGOTOPreviousPage,
    mapEditor
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      loading: false,
      formData: {
        id: '',
        mapUrl: '',
        markers: []
      },
      required,
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.loading = true

      this.$store.dispatch('fetchReservationMap', this.$route.params.id).then(response => {
        this.formData.id = this.$route.params.id
        this.formData.mapUrl = response.data.entity.mapUrl
        this.processEntity(response.data.entity, onlyTabs)
      })

      this.$store.dispatch('fetchReservationMapMarkers').then(response => {
        this.formData.markers = response.data.markers
        this.mapEditable = response.data.mapEditable
      }).finally(() => {
        this.loading = false
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {
        this.formData.id = this.$route.params.id
        this.formData.pricingTypeName = entity.pricingTypeName
        this.formData.startDayType = entity.start_day_type
        this.formData.minNumberOfDays = entity.min_number_of_days
        this.formData.maxNumberOfDays = entity.max_number_of_days
        this.formData.minNumberOfParticipants = entity.min_number_of_participants
        this.formData.maxNumberOfParticipants = entity.max_number_of_participants
        this.formData.price = parseInt(entity.gross_price)
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {

      let mapEditorData = this.$refs["map-editor"].getData()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateReservationMap', fd).then(response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
