<template>
  <b-tabs content-class="mt-3">
    <log-tab
      ref="tabLogTable"
      :entity-id="entityId"
      entity-type="map"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    logTab,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    },
    refreshLog() {
      this.$refs.tabLogTable.initData()
    },
  }
}
</script>

<style scoped>

</style>
